import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { configJSON } from "./AddressController.web";

type httpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const contentType = "application/json";

export interface Props {
    // Customizable area start
    // Customizable area end
}

interface S {
    // Customizable area start
    transactionList: TransactionData[],
    totalReferralPoints: number,
    equivalentPoint: number
    // Customizable area end
}

interface SS {
    // Customizable area start
    // Customizable area end
}

interface ApiCall {
    endPoint: string,
    method: httpMethod
}

export interface TransactionData {
    id: string,
    type: string,
    attributes: {
        id: number,
        points: number,
        created_at: string,
        comments: string
    }
}

export default class ReferralController extends BlockComponent<Props, S, SS> {

    getTransactionListAPIid: string = '';
    referralPointsAPIid: string = '';

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            transactionList: [],
            totalReferralPoints: 0,
            equivalentPoint: 0
            // Customizable Area End
        };
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        await this.getTransactionListData();
        await this.getTotalReferralPoints();
    }

    receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson) {
                // Transaction list data response
                if (apiRequestCallId === this.getTransactionListAPIid) {
                    this.setState({
                        transactionList: responseJson?.data?.data,
                        equivalentPoint: responseJson?.point_equivalent
                    });
                }

                // Total referral points data response
                if (apiRequestCallId === this.referralPointsAPIid) {
                    this.setState({
                        totalReferralPoints: responseJson?.total_points
                    });
                }
            }

        }
        // Customizable Area End
    }

    /**
     * Function to get referral points transaction history data
     */
    getTransactionListData = async () => {
        this.getTransactionListAPIid = await this.apiCall({
            method: configJSON.apiGetMethod,
            endPoint: configJSON.getReferralTransactionHistory
        });
    }

    /**
     * Function to get user's total referral points available
     */
    getTotalReferralPoints = async () => {
        this.referralPointsAPIid = await this.apiCall({
            method: configJSON.apiGetMethod,
            endPoint: configJSON.getTotalReferralPoints
        });
    }

    /**
     * Function that modifies date format from UTC to MMM DD, YYYY (Mar 23, 2014)
     * @param date UTC date in string
     * @returns String
     */
    changeDateFormat(date: string): string {
        const _date = new Date(date);
        return Intl.DateTimeFormat("en", {
            year: 'numeric',
            day: '2-digit',
            month: 'short'
        }).format(_date);
    }

    /**
     * Function to make api call
     * @param data
     * @returns Message ID
     */
    apiCall = async (data: ApiCall) => {

        const { method, endPoint } = data;

        // Create API request ID
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // Add api endpoint
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        // Get token from local storage and to headers
        const token = localStorage.getItem("token");
        const headers = {
            "Content-Type": contentType,
            token,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );

        // Add api request method
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        // Send the request to run engine
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

}