const content = {
  login: "Log In",
  signup: "Sign Up",
  getStarted: "Get started and discover the best offers around you",
  signIn: "Sign In",
  forgotPassword: "Forgot Password ?",
  continueVia: "or Continue via",
  facebook: "Facebook",
  google: "Google",
  bySigningIn: "By Signing In you agree with our",
  termNcondition: "Terms & Conditions",
  privacyPolicy: "Privacy Policy",
  skipAsGuest: "Skip & Continue as Guest",
  passwordSuggestionMsg:
    "Enter a passsword with alphabets A-z, numbers 0-9 and a symbols",
  signupVia: "or Sign up via",
  downloadApp: "Download the App for Free",
  studioStore: "Studio Store",
  usefulLinks: "Useful Links",
  aboutUs: "About Us",
  deliveryNReturn: "Delivery & Returns",
  contactUs: "Contact Us",
  quickLinks: "Quick Links",
  helpCenters: "Help Centers",
  referral: "Referral Points",
  transactionHistory: "Transaction History",
  referralTransactionTable: {
    id: "ID",
    points: "Points",
    comments: "Comments",
    created: "Created"
  },
  faqs: "FAQs",
  promotions: "Promotions",
  copyrightText: "Copyright © 2020 Studio Store, USA. All rights reserved.",
  dummyText:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  loginNSignup: "Login / Signup",
  home: "Home",
  market: "Market",
  shop: "Shop",
  wishlist: "Wishlist",
  myOrders: "My Orders",
  savedAddresses: "Saved Addresses",
  connectedAccounts: "Connected Accounts",
  logout: "Logout",
  welcomeToStore: "Welcome to our Store!",
  callUs: "CALL US",
  mobileNumber: "+1 123 456 1234",
  newArrivals: "New Arrivals",
  edit: "Edit",
  delete: "Delete",
  addNewAddress: "Add New Address",
  noAddressesSaved: "No addresses saved!",
  noAddressesList: "No addresses have been saved to the address list yet!",
  addAddress: "Add an Address",
  name: "Name",
  flatHouseApartment: "Flat / House / Apartment No.",
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  city: "City",
  state: "State",
  country: "Country",
  pinCode: "Pin Code",
  phoneNumber: "Phone Number",
  saveAddress: "Save Address",
  editAddress: "Edit Address",
  deleteAddress: "Delete Address",
  areYouSureDeleteAddress: "Are you sure you want to delete the address ?",
  areYouSureCancelOrder: "Are you sure you want to cancel order ?",
  cancelOrder: "Cancel order",
  cancel: "Cancel",
  yesDelete: "Yes, Delete",
  connectedAs: "Connected as",
  connectfacebook: "Connect Facebook Account",
  connectGoogle: "Connect Google Account",
  disconnect: "Disconnect",
  profile: "Profile",
  helpCenter: "Help Center",
  logoutMessage: "Are you sure you want to logout from Studio Store ?",
  yesConfirm: "Yes, Confirm",
  noAnyOrder: "No orders found",
  browseItemNOrderIt: "You haven’t order any items, Browse items and order it",
  browseProducts: "Browse Products",
  noWishlist: "No Wishlist",
  youHaveNotWishlist:
    "You haven’t wishlisted any items, Browse items and wishlist it",
  orderDetails: "Order Details",
  orderNumber: "Order Number",
  orderedOn: "Ordered on",
  writeAReview: "Write a Review",
  trackingID: "Tracking ID",
  quantity: "Quantity",
  orderStatus: "Order status",
  shippingAddress: "Shipping Address",
  errorOccured: "Oh Noes!, Error Occured",
  rateNReview: "Rate and Review",
  rateOurProduct: "Rate our Product",
  youCannotReview: "You can't review a product until you purchase it.",
  submit: "Submit",
  ok: "OK",
  revicedValue: "This is the reviced value",
  email: "Email",
  phoneNo: "Phone No",
  changePassword: "Change Password",
  editProfile: "Edit Profile",
  removePicture: "Remove Picture",
  saveProfile: "Save Profile",
  enterPasswordWithAlpha: "Enter a passsword with alphabets A-z, numbers 0-9 and a symbols",
  enterCurrentPassword: "Enter current password",
  enterNewPassword: "Enter new password",
  reEnterNewPassword: "Re-enter new password",
  passwordChangedSuccessfully: "Password changed successfully !",
  goBackNBrowse:
    "You can now go back and continue browsing products Enjoy Shopping !",
  goToProfile: "Go to Profile",
  cancelOrder: "Cancel Order",
  sale: "Sale",
  currency: "INR",
  goToCart: "Go To Cart",
  addToCart: "Add To Cart",
  updateCart: "Update Cart",
  outOfStock: "Out of Stock",
  buyNow: "Buy Now",
  createNewPassword: "Create New Password",
  otpHasBeenSent: "OTP has been sent to your email id.",
  enterYourRegisterEmail:
    "Enter your registered Email, we’ll sent you OTP to reset your password",
  invalidEmail: "Invalid Email / Phone Number.",
  emailMustBeValid: "Email / Phone Number must be a valid",
  pleaseEnterOTP: "Please Enter OTP",
  pleaseEnter5DigitOTP: "Please enter 5 digit OTP",
  invalidOTP: "OTP is Invalid",
  resendOTP: "Resend OTP",
  updatePassword: "Update Password",
  fashionAndTrendComeTogather: "Fashion and Trend come togather",
  upto30Off: "Upto 30% off",
  shopNow: "Shop Now",
  otpVerification: "OTP Verification",
  verifyOTP: "Verify OTP",
  forgotPasswordContentText:
    "Enter your registered Email / Phone Number and we’ll sent you OTP to reset your password",
  // "Enter your registered Email and we’ll sent you OTP to reset your password",
  category: "Category",
  price: "Price Range",
  color: "Color",
  brand: "Brands",
  size: "Size",
  tag: "Tags",
  inr: "₹INR",
  discountItem: "Discounted Items",
  outStock: "Exclude OutofStock",
  apply: "Apply",
  placeOrderConfirmation: "Are you sure want to place the order?",
  okay: "Okay",
  defaultaddressContent: "Your default address has been changed.",
  defaultAdreess: "Default Address",

  ///home page
  homeFreeDeliveryStrip: {
    FreeDelivery: "Free Delivery",
    FreeDeliveryDescription: "Free Shipping on all order",
    SecurePayment: "Secure Payment",
    SecurePaymentDescription: "On every order over $100.00",
    return: "On every order over $100.00",
    returnDescription: "Guarantee under 7 days",
    support: "Support 24/7",
    supportDescrip: "Support online 24 hours a day",
  },
  HomeCollection: "Collections",
  NewCollection: "New Collection",
  // product card at home page
  productCard: {
    viewAll: "View All",
    off: "% Off",
  },
  rupeeSymbol: "₹ ",
  inStock: "In stock online",
  soldOut: "Currently out of stock",
  size: "SIZE",
  color: "COLOR",
  itemOutOfStock: "The Item is currently out of stock",
  notifyMe: "Notify me - when available",
  NoProductFound: "No Product Found",
  Description: "Description",
  noDescription: "No Description Available",
  ProductRating: "Product Rating",
  zeroRate: "Based on 0 Ratings",
  RateProduct: "Rate Product",
  WriteReview: "Write Review",
  ShowMore: "Show More",
  showLess: "show Less",
  cart: "Cart",
  cartEmpty: "Your cart is empty",
  cartEmptyDescription: "Looks like you haven’t added anything to your cart yet",
  MovetoWishlist: "Move to Wishlist",
  remove: "Remove",
  YourCart: "Your Cart",
  qty: "Quantity",
  amount: "Amount",
  SubTotal: "Sub Total",
  taxes: "Taxes",
  DeliveryCharges: "Delivery Charges",
  couponApplied: "Great ! Coupon Code Applied",
  referralPointsApplied: "Referral Points applied successfully",
  removeCoupon: "Remove Coupon",
  cancelReferralPoints: "Cancel",
  TotalAmount: "Total Amount",
  proceed: "Proceed",
  ///filter page
  products: "Products",
  filter: "Filter",
  sort: "Sort by",
  clearAll: "Clear All",
  loadMore: "Load More",
  AllProduct: "All Product",
  LowtoHigh: "Price - Low to High",
  HightoLow: "Price - High to Low",
  ByPopularity: "By Popularity",
  ByNewest: "By Newest",
  Recommended: "Recommended",
  okay: "Okay",
  requestProcessed: "Request Processed",
  willNotify: "You'll get notified once the product is back in stock",
  RecommendedProducts: "Recommended Products",
  notification: "Notification",
  noNotificationsTitle: "No notifications yet !",
  noNotificationsMessage:
    "Browse for products or check out our latest additions.",
  continueShoppingButton: "Continue Shopping",
  deleteNotificationContentMessage:
    "Are you sure you want to delete the notification ?",
  deleteNotificationTitleMessage: "Delete Notification",
  readAll: "Read All",
  deleteAll: "Delete All",
  responsiveSort: "Sort",

  //Additional Features Content
  continuePhoneLogin: "Continue via with Phone Number",
  phoneLoginTagLine: "Enter your phone number and we’ll sent you OTP to login.",
  inavlidPhone: "Invalid Phone Number",
  phoneMustBeValid: "Phone Number must be valid",
  continueViaEmail: "Continue via Email",
  subscribe: "Subscribe",
  subscribed: "Subscribed",
  subscription: "Subscription",
  subscriptionPackage: "Select Subscription Package",
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  subscriptionPeriod: "Select Subscription Period",
  subscriptionTimeslot: "Preferred Time Slot",
  totalPrice: "Total Price",
  material: "Material",
  kg: "Kg",
  cancelled: "Cancelled",
  otpHasBeenSenPhone: "OTP has been sent to your Phone Number.",
  //checkout page
  checkOut: 'Checkout',
  //payment
  payment: 'Payment',
  product: 'Product',
  maxReferralPointsToRedeem: 30,
  taxInclusive: "(Inclusive tax.)"
};

export default content;
