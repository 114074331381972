import ReferralController, { Props, TransactionData } from "./ReferralController.web";
import React from "react";
import "../assets/styles/referral.css";
// @ts-ignore
import content from "../../studio-store-ecommerce-components/src/content.js";


class Referral extends ReferralController {

    constructor(props: Props) {
        super(props);
    }

    /**
     * Function to render transaction history data
     * @param data Transaction Data
     * @returns React JSX element
     */
    renderTransactionHistory(data: TransactionData) {
        return (<tr key={data?.attributes?.id} className="bg-white referral-transaction-data">
            <td className="p-3">{data?.attributes?.id}</td>
            <td className="p-3">{data?.attributes?.points > 0 ? '+' : ''}{data?.attributes?.points}</td>
            <td className="p-3">{data?.attributes?.comments}</td>
            <td className="p-3">{this.changeDateFormat(data?.attributes?.created_at)}</td>
        </tr>)
    }

    render() {
        return (
            <>
                <div className="profile-pg-inner-wrap profile-pg-inner-no-add p-3 bg-white radius-10 profile-pg-mb-30">
                    <p className="referral-points">
                        {this.state.totalReferralPoints} {this.state.totalReferralPoints > 1 ? 'Points' : 'Point'}
                    </p>
                    <p className="referral-info fs-20"> <span className="color-grey">Each</span> 1 Referral Point <span className="color-grey">can be redeemed for</span> INR {this.state.equivalentPoint}</p>
                </div>

                {this.state.transactionList?.length ?
                <>
                    <p className="fs-20">{content.transactionHistory}</p>
                    <div className="transaction-history-container profile-pg-inner-no-add radius-10 profile-pg-mb-30 w-100">
                        <table className="w-100 referral-history-table">
                            <thead>
                                <tr className="color-grey fs-14">
                                    <th className="w-10 p-2">{content.referralTransactionTable.id}</th>
                                    <th className="w-10 p-2">{content.referralTransactionTable.points}</th>
                                    <th className="w-60 p-2">{content.referralTransactionTable.comments}</th>
                                    <th className="w-20 p-2">{content.referralTransactionTable.created}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.transactionList.map((item: TransactionData) => this.renderTransactionHistory(item))}
                            </tbody>
                        </table>
                    </div>
                </>
                    : ""
                }
            </>
        );
    }
}

export default Referral;