//@ts-nocheck;
import React from "react";
import { Link } from "react-router-dom";
import "../assets/styles/index.scoped.css";
// @ts-ignore
import content from "../../studio-store-ecommerce-components/src/content.js";
// Customizable Area Start
// Customizable Area End

import BreadCrumbs from "../../studio-store-ecommerce-components/src/BreadCrumbs";

function ProfileBreadcrumbs(props: any) {
  // Customizable Area Start
  const urlMatch = props?.onProfile?.onProps?.match?.params?.slug;
  let MactchBread = "";
  if (urlMatch == undefined) {
    switch (props.activeIndex) {
      case "1":
        MactchBread = "";
        break;
      case "2":
        MactchBread = "Wishlist";
        break;
      case "3":
        MactchBread = "My Orders";
        break;
      case "4":
        MactchBread = "Saved Addresses";
        break;
      case "5":
        MactchBread = "Connected Accounts";
        break;
      case "6":
        MactchBread = "Referral Points";
        break;
      case "7":
        MactchBread = "Help Center";
        break;
      case "8":
        MactchBread = "Notification";
        break;
      default:
        MactchBread = "";
    }
    let breadcrumbs = [
      {
        label: content.home,
        link: "/home-page",
      },
      {
        label: content.profile,
        link: "/profilebio",
      },
    ];
    if (MactchBread !== "") {
      breadcrumbs.push({ label: MactchBread, link: "" });
    }
    return <BreadCrumbs list={breadcrumbs} />;
  } else {
    let breadcrumbs = [
      {
        label: content.home,
        link: "/home-page",
      },
      {
        label: content.profile,
        link: "",
      },
    ];
    return <BreadCrumbs list={breadcrumbs} />;
  }
  // Customizable Area End
}

export default ProfileBreadcrumbs;
// Customizable Area Start
// Customizable Area End
