//@ts-nocheck;
import React, { Fragment } from "react";
import { Button, FormGroup, Alert } from "reactstrap";
import { Formik, Field, Form, FormikErrors, FormikTouched } from "formik";
import { Link, withRouter } from "react-router-dom";
import { FaFacebookF, FaRegEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";
import { CgSpinner } from "react-icons/cg";
// @ts-ignore
import content from "../../studio-store-ecommerce-components/src/content.js";
import "../assets/styles/index.css";
import EmailAccountRegistrationController from "./EmailRegistartionController.web";
import {
  EmailIcon,
  pwdIcon,
  userIcon,
  referralImg,
} from "./assets";
import Loader from "../../studio-store-ecommerce-components/src/AppLoader/AppLoader.web";
// @ts-ignore
import includes from "lodash/includes";
// Customizable Area Start
interface FormValues {
  FullName: string;
  Email: string;
  password: string;
  referral: string;
}
// Customizable Area End
export class EmailRegistartion extends EmailAccountRegistrationController {
  // Customizable Area Start
  public shouldComponentUpdate(a: any, b: any) {
    if (b.showAlert) {
      setTimeout(() => {
        this.setState({
          showAlert: false,
          message: "",
          messageType: "",
        });
      }, 2000);
    }
    return true;
  }
  initialFormValues: FormValues = {
    FullName: "",
    Email: "",
    password: "",
    referral: ''
  };
  // Customizable Area Start

  /**
   * Function to render show/hide password button
   * @param addMargin Boolean
   * @returns JSX statement
   */
  // renderShowHidePasswordButton(addMargin: boolean) {
  //   if (this.state.showPass) {
  //     return (<FaEyeSlash
  //       onClick={this.showPassword}
  //       data-test-id="showPassword"
  //       style={addMargin ? { marginRight: 20 } : undefined}
  //       className="yt-login-pass-vie-icn mt-1"
  //       id={this.props?.isOpenPopUp ? "eyeicon" : ""}
  //     />)
  //   } else {
  //     return (
  //       <FaEye
  //         onClick={this.showPassword}
  //         data-test-id="hidePassword"
  //         className="yt-login-pass-vie-icn mt-1"
  //         id={this.props?.isOpenPopUp ? "eyeicon" : ""}
  //         style={addMargin ? { marginRight: 20 } : undefined}
  //       />
  //     )
  //   }
  // }

  /**
   * Function to render social login buttons
   * @returns React JSX element
   */
  renderSocialLoginButtons() {
    if (this.state.isSocialLoginsEnabled) {
      return (<>
        <p className="yt-signup-via-tag">{content.signupVia}</p>
        <div className="d-flex align-items-center justify-content-center">
          {this.state.isShowFb && (
            <Button
              data-test-id="facebookLogin"
              onClick={() => this.connectFacebook()}
              color="secondary d-flex align-items-center mr-3 yt-signup-via-fb"
            >
              <FaFacebookF className="mr-2" /> {content.facebook}
            </Button>
          )}
          {this.state.isShowGoogle && (
            <Button
              data-test-id="googleLogin"
              onClick={() => this.connectGoogle()}
              color="secondary d-flex align-items-center yt-signup-via-email"
            >
              <FaRegEnvelope className="mr-2" /> {content.google}
            </Button>
          )}
        </div>
      </>)
    } else {
      return "";
    }
  }

  /**
   * Checks if any error present in Name field
   * @param errors Formik errors
   * @param touched Formik touched
   * @returns Boolean
   */
  isErrorInName(errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>): boolean {
    return errors.FullName && touched.FullName ? true : false;
  }

  /**
   * Checks if any error present in Email field
   * @param errors Formik errors
   * @param touched Formik touched
   * @returns Boolean
   */
  isErrorInEmail(errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>): boolean {
    return errors.Email && touched.Email ? true : false;
  }

  /**
   * Checks if any error present in Referral field
   * @param errors Formik errors
   * @param touched Formik touched
   * @returns Boolean
   */
  isErrorInReferral(errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>): boolean {
    return errors.referral && touched.referral ? true : false;
  }

  /**
   * Checks if any error present in Password field
   * @param errors Formik errors
   * @param touched Formik touched
   * @returns Boolean
   */
  // isErrorInPassword(errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>): boolean {
  //   return errors.password && touched.password ? true : false;
  // }

  /**
   * Function to render error message beneath form field
   * @param errorMessage Error message from formik
   * @returns React JSX element
   */
  renderErrorElement(errorMessage: string | undefined) {
    return (
      <span
        className="invalid-feedback"
        style={{ position: "absolute", textAlign: 'left' }}
      >
        {errorMessage}
      </span>
    )
  }

  /**
   * Function to conditional render spinner and submit button
   * @returns React JSX element
   */
  renderSubmitBtn() {
    if (this.state.showSpinner) {
      return (
        <CgSpinner
          style={{
            color: "black",
            fontSize: 32,
            width: "100%",
            margin: 10,
          }}
          className="w3-spin"
        />
      )
    } else {
      return (
        <Button
          type="submit"
          className="signup-btn w3-ripple"
          block
          color="secondary yt-signup-btn"
        >
          {content.signup}
        </Button>
      )
    }
  }

  /**
   * Function to conditionally render password hint and errors
   * @param errors Formik Errors
   * @param touched Formik Touched
   * @returns React JSX element
   */
  // renderPasswordHint(errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>) {
  //   if (this.isErrorInPassword(errors, touched)) {
  //     return this.renderErrorElement(errors.password);
  //   } else {
  //     return (
  //       <span className="yt-signup-pass-info pb-1">
  //         {content.passwordSuggestionMsg}
  //       </span>
  //     );
  //   }
  // }

  /**
   * Function to render email error if present
   * @returns React JSX element
   */
  renderEmailError() {
    if (this.state.emailErr?.length > 0) {
      return (
        <span className="err_invalid">
          {this.state.emailErr}
          <br />
        </span>
      )
    } else {
      return (<></>)
    }
  }

  /**
   * Function to render alert
   * @returns React JSX element
   */
  showAlert() {
    if (this.state.showAlert) {
      return (
        <>
          <Alert
            color={this.state.messageType ? this.state.messageType : ""}
          >
            {this.state?.message}
          </Alert>
        </>);
    } else {
      return "";
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
        <div className="yt-signup-wrap">
          <h2 className="yt-signup-tag-line">{content.getStarted}</h2>
          {/* @ts-ignore */}
          <div className="yt-signup-form mb-4">
            <Formik
              initialValues={this.initialFormValues}
              onSubmit={this.signupUser}
              validationSchema={this.signUpValidation2}
            >
              {({ errors, touched, setValues, values, handleSubmit }) => {
                return (
                  <Form>
                    <FormGroup>
                      <img
                        alt="User Profile Image"
                        src={userIcon}
                        className="yt-login-icn"
                      />
                      <Field
                        name="FullName"
                        type="text"
                        data-test-id="fullName"
                        id="FullName"
                        placeholder="Full Name"
                        className={
                          "form-control" +
                          (this.isErrorInName(errors, touched)
                            ? " is-invalid invalid"
                            : "")
                        }
                      />
                      {this.isErrorInName(errors, touched) && (
                        this.renderErrorElement(errors.FullName)
                      )}
                    </FormGroup>
                    <FormGroup>
                      <img
                        alt="Email Icon"
                        src={EmailIcon}
                        className="yt-login-icn"
                      />
                      <Field
                        name="Email"
                        type="text"
                        data-test-id="email"
                        placeholder="Email / Phone Number"
                        className={
                          "form-control" +
                          (this.isErrorInEmail(errors, touched)
                            ? " is-invalid invalid"
                            : "")
                        }
                      />
                      {this.isErrorInEmail(errors, touched) && (
                        this.renderErrorElement(errors?.Email)
                      )}
                    </FormGroup>
                    <FormGroup>
                      <img
                        alt="Referral Icon"
                        src={referralImg}
                        className="yt-login-icn"
                      />
                      <Field
                        name="referral"
                        type="text"
                        data-test-id="referralCode"
                        placeholder="Referral Code"
                        className={
                          "form-control" +
                          (this.isErrorInReferral(errors, touched)
                            ? " is-invalid invalid"
                            : "")
                        }
                      />
                      {this.isErrorInReferral(errors, touched) && (
                        this.renderErrorElement(errors.referral)
                      )}
                    </FormGroup>
                    <FormGroup translate="yes" className="mt-3 ">
                      <img
                        alt="Password Icon"
                        src={pwdIcon}
                        className={
                          (errors.password && touched.password) ||
                          this.state.loginError.length > 0
                            ? " yt-login-icn2"
                            : "yt-login-icn"
                        }
                      />
                      <Field
                        name="password"
                        type={this.state.showPass ? "text" : "password"}
                        id="password"
                        placeholder="Password"
                        className={
                          "form-control" +
                          (errors.password && touched.password
                            ? " is-invalid invalid"
                            : "")
                        }
                      />
                      {touched.password && errors.password ? (
                        this.state.showPass ? (
                          <FaEyeSlash
                            onClick={this.showPassword}
                            className={
                              this.state.loginError.length > 0
                                ? "yt-login-pass-vie-icn2"
                                : "yt-login-pass-vie-icn"
                            }
                            style={{ marginRight: 20 }}
                          />
                        ) : (
                          <FaEye
                            onClick={this.showPassword}
                            className={
                              this.state.loginError.length > 0
                                ? "yt-login-pass-vie-icn2"
                                : "yt-login-pass-vie-icn"
                            }
                            style={{ marginRight: 20 }}
                          />
                        )
                      ) : this.state.showPass ? (
                        <FaEyeSlash
                          onClick={this.showPassword}
                          className={
                            this.state.loginError.length > 0
                              ? "yt-login-pass-vie-icn2"
                              : "yt-login-pass-vie-icn"
                          }
                        />
                      ) : (
                        <FaEye
                          onClick={this.showPassword}
                          className={
                            this.state.loginError.length > 0
                              ? "yt-login-pass-vie-icn2"
                              : "yt-login-pass-vie-icn"
                          }
                        />
                      )}
                      {touched.password && errors.password && (
                        <span
                          className="invalid-feedback"
                          style={{ position: "absolute" }}
                        >
                          {errors.password}
                        </span>
                      )}
                      {this.state.loginError.length > 0 ? (
                        <span
                          className="err_invalid mt-2"
                          style={{ position: "absolute" }}
                        >
                          {this.state.loginError}
                        </span>
                      ) : (
                        <></>
                      )}
                    </FormGroup>
                    {this.renderEmailError()}
                    <Fragment>
                      {this.renderSubmitBtn()}
                    </Fragment>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="yt-signup-bottom-info text-center">
            <div className="my-4text-center">
              <span
                data-test-id="guestRegister"
                style={{ cursor: "pointer" }}
                className="w3-ripple yt-signup-skip-btn"
                onClick={() => this.guestRegister()}
              >
                {content.skipAsGuest}
              </span>
            </div>
            {this.renderSocialLoginButtons()}
          </div>
          <div className="lp-bottom-links">
            <p className="yt-signup-aggre-tag mb-3 text-center">
              {content.bySigningIn}
            </p>
            <div className="d-flex yt-signup-term-privacy-link justify-content-center">
              <Link data-test-id="termsAndCondition" to="/help-center/Terms%20Of%20Service">
                {content.termNcondition}
              </Link>
              <span className="mx-2">&</span>
              <Link data-test-id="privacyPolicy" to="/help-center/Privacy%20Policy">
                {content.privacyPolicy}
              </Link>
            </div>
          </div>
          {this.showAlert()}
        </div>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}

// @ts-ignore
export default withRouter(EmailRegistartion);
// export { EmailRegistartion };
// Customizable Area Start
// Customizable Area End
